<template lang="pug">
  LogEmailProvider(:getLogs="getSentEmails" v-slot:default="emailLogs")
    baseEmailTemplateProvider
      template(v-slot:default="baseEmail")
        EmailTemplatesSendProvider(
          :ID="item.ID"
          :model="item"
          :formErrors="baseEmail.formErrors"
          v-slot:default="sendProvider"
          @send="emailLogs.addToLogs"
        )
          EmailTemplates(
            :items="baseEmail.list"
            :itemsLoading="baseEmail.listLoading"
            :editedTemplate="baseEmail.editedTemplate"
            :loadData="baseEmail.loadData"
            :logs="baseEmail.logs"
            :formErrors="baseEmail.formErrors"
            :processing="baseEmail.processing"
            :maxItemsShowing="11"
            @templateSelected="baseEmail.loadTemplateData"
            @onAddCustomTemplate="baseEmail.loadCreateTemplate"
            @submit="sendProvider.sendEmail"
            @save="baseEmail.post"
            @update="baseEmail.update"
            @remove="baseEmail.remove"
          )
            template(v-slot:tooltip)
              v-menu(left offset-x max-width="400" open-on-hover content-class="templates-tooltip" @input="emailLogs.setTemplateTooltipShowing")
                template(v-slot:activator="{ on }")
                  v-icon(v-on="on") mdi-email
                templatesLog(:list="emailLogs.logs")
            template(v-slot:side)
              v-col
                h3.email-templates__subtitle Email logs
                templatesLog(:list="emailLogs.logs" :loading="emailLogs.logsLoading")
</template>

<script>
export default {
  name: 'CustomEmails',

  props: {
    item: { type: Object, required: true }
  },

  methods: {
    getSentEmails() {
      return this.item.sentEmails
    }
  },

  components: {
    EmailTemplatesProvider: () => import('@/app/admin/modules/candidates/components/candidates/CandidatesEmailTemplatesProvider.vue'),
    EmailTemplates: () => import('@/app/admin/modules/candidates/components/candidates/CandidatesEmailTemplates'),
    baseEmailTemplateProvider: () => import('@/app/admin/modules/candidates/components/candidates/CandidatesEmailBaseProvider.vue'),
    EmailTemplatesSendProvider: () => import('@/app/admin/modules/candidates/components/candidates/CandidatesEmailSendProvider.vue'),
    LogEmailProvider: () => import('@/app/admin/modules/candidates/components/candidates/CandidateEmailLogProvider.vue'),
    templatesLog: () => import('@/app/admin/modules/candidates/components/candidates/CandidatesETempLog.vue'),
  }
}
</script>
